import { Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";

const BridgeUpHeader = () => {
  return (
    <>
      <Stack
        position="relative"
        spacing={2}
        bgcolor="#000000"
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="245px"
        className="header-wrapper"
      >
        <Typography
          variant="body2"
          fontWeight={700}
          color="primary"
          align="center"
        >
          CALCULATOR
        </Typography>
        <Typography
          sx={{
            fontSize: "35px",
          }}
          variant="h2"
          fontWeight={700}
          align="center"
        >
          BridgeUp Calculator
        </Typography>
      </Stack>
      {/* <img
        className="five-line-img"
        src="https://assets.website-files.com/61f763783504a2866ee4eccb/6210b949dd97b019d43ac798_Frame%201923.svg"
      /> */}
    </>
  );
};

export default BridgeUpHeader;
