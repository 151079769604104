import { Chart as ChartJS, registerables } from "chart.js";
import { Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Chart } from "react-chartjs-2";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as React from "react";

ChartJS.register(...registerables);
function createGradient(ctx) {
  const gradient = ctx.createLinearGradient(100, 0, 150, 0);
  gradient.addColorStop(0, "#5FB8FF");
  gradient.addColorStop(1, "#3262EC");
  return gradient;
}

export function BarChart(props) {
  const { values, labels } = props;

  const mobile = !useMediaQuery("(min-width:600px)");

  const lap = !useMediaQuery("(min-width:1025px)");

  const chartRef = useRef(null);

  const [chartData, setChartData] = useState({
    labels: labels,
    datasets: [],
  });

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    padding: 20,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      x: {
        ticks: {
          color: "white",
        },
        display: true,
        grid: {
          borderColor: "#7D87A6",
        },
      },
      y: {
        display: true,
        grid: {
          borderColor: "#7D87A6",
        },
        beginAtZero: true,
        ticks: {
          color: "white",
          callback: function (label, index, labels) {
            if (label > 1000000000000)
              return (label / 1000000000000).toString() + "T";
            if (label > 1000000000)
              return (label / 1000000000).toString() + "B";
            if (label > 1000000) return (label / 1000000).toString() + "M";
            if (label > 1000) return (label / 1000).toString() + "K";
            return label.toString();
          },
        },
        scaleLabel: {
          display: true,
          labelString: "1k = 1000",
        },
      },
    },
  };

  useEffect(() => {
    const chart = chartRef.current;

    if (!chart) {
      return;
    }

    const chartData = {
      labels: labels,
      datasets: [
        {
          label: "BridgeUp",
          data: values,
          backgroundColor: [
            createGradient(chart.ctx),
            "rgba(54, 83, 107, 1)",
            "rgba(54, 83, 107, 1)",
          ],
          fill: true,
          barThickness: mobile ? 40 : 70,
          borderWidth: 0,
        },
      ],
    };
    setChartData(chartData);
  }, [values, labels, mobile]);

  return (
    <Box
      sx={{
        width: mobile ? 220 : lap ? 400 : 500,
        position: "relative",
        minHeight: 300,
      }}
    >
      <Chart ref={chartRef} type="bar" data={chartData} options={options} />
    </Box>
  );
}
