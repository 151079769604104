import axios from "axios";
const sgMail = require("@sendgrid/mail");
sgMail.setApiKey(
  "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"
);

export const login = () => {
  let UTMTagsFromLocal = window.localStorage.getItem("UTMTags");
  UTMTagsFromLocal = UTMTagsFromLocal || "";
  window.location.href =
    "https://app.bridgeup.com/login?_gl=1*44uu71*_ga*Mzk2MzQ3OTM3LjE2NDI0MzM0NDE.*_ga_D1N0RF1W6D*MTY0NTU5MjU5MC4yMi4wLjE2NDU1OTI1OTAuMA.." +
    (UTMTagsFromLocal ? "&" : "") +
    UTMTagsFromLocal;
};
export const signup = () => {
  let UTMTagsFromLocal = window.localStorage.getItem("UTMTags");
  UTMTagsFromLocal = UTMTagsFromLocal || "";
  window.location.href =
    "https://app.bridgeup.com/signup?_gl=1*14kywor*_ga*Mzk2MzQ3OTM3LjE2NDI0MzM0NDE.*_ga_D1N0RF1W6D*MTY0NTU5MjU5MC4yMi4xLjE2NDU1OTI2ODcuMA" +
    (UTMTagsFromLocal ? "&" : "") +
    UTMTagsFromLocal;
};

export const isValidEmail = (email) => {
  var regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

export const pushData = (body) => {
  // remove comments for updateding previous calculations done by a user.
  // const calc_id = localStorage.getItem("calc_id")
  // if(!calc_id){
  // axios.post("https://v1.nocodeapi.com/calc/webflow/xtjPaSArOiTZaeJz", body);
  // .then((response) => {
  //   localStorage.setItem("calc_id",response.data._id);
  // });
  // }
  // if(calc_id){
  //     axios.put("https://v1.nocodeapi.com/calc/webflow/xtjPaSArOiTZaeJz?itemId="+calc_id, {slug: "updated", ...body}).then((response) => {
  //         localStorage.setItem("calc_id",response.data._id);
  //       });
  // }

  const message = {
    to: "ops@bridgeup.com",
    from: body.email,
    subject: `Need ARR of ${body.arr}`,
    html: `<p><strong>Email :</strong> ${body.email}</p>
        <p><strong>ARR :</strong> ${body.arr} Rupees</p>
        <p><strong>Type :</strong> ${body.type}</p>
        <p><strong>Runway :</strong> ${body.runway} months</p>
        <p><strong>Customers :</strong> ${body.customers}</p>
        please reach me out!`,
  };
  // sgMail
  //   .send(message)
  //   .then(() => {
  //     console.log("mail sent");
  //   })  
  //   .catch((e) => {
  //     console.log(e);
  //   });
};
