import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import LapImg from "../assets/lapimg.png";
import { signup } from "../common";
import * as React from "react";

function StaticSection1() {
  const mobile = !useMediaQuery("(min-width:769px)");
  return (
    <Grid
      className="static-section-wrapper"
      container
      spacing={{ xs: 2, md: 3 }}
      alignItems="center"
      style={{
        backdropFilter: "blur(20px)",
        background: "#FFFFFF05",
      }}
    >
      <Grid
        className="static-section-left"
        display={"flex"}
        flexDirection="column"
        alignItems={"flex-start"}
        justifyContent="center"
        xs={12}
        xl={7.5}
        md={6}
      >
        <Typography
          className="static-access-h6"
          // variant="h3"
          fontWeight={700}
          color="text.primary"
          fontSize={"40px"}
        >
          You could access upto ₹200 Cr
        </Typography>
        <Typography
          style={{
            color: "#A3AED0",
          }}
          variant="h6"
          fontWeight={400}
          color="text.primary"
          className="typo-text"
          sx={{ mt: 3 }}
        >
          Sign up now and get your funds in less than 48 hours
        </Typography>
        <Button
          className="static-signup-btn"
          onClick={signup}
          variant="contained"
          sx={{ mt: 4 }}
        >
          SIGN UP
        </Button>
      </Grid>
      <Grid item xs={12} xl={4.5} md={6}>
        <img src={LapImg} alt="" style={{ width: "100%", height: "auto" }} />
      </Grid>
    </Grid>
  );
}
export default StaticSection1;
