import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { alpha, styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import { ReactComponent as CalculatorIcon } from "../assets/Calculator-Icon.svg";
import { ReactComponent as CompanyIcon } from "../assets/Company-Icon.svg";
import { ReactComponent as InvestorsIcon } from "../assets/Investors-Icon.svg";
import { ReactComponent as Logo } from "../assets/logo.svg";
import { ReactComponent as ResourcesIcon } from "../assets/Resources-Icon.svg";
import { ReactComponent as MenuIcon } from "../assets/SideMenu-Icon.svg";
import { login, signup } from "../common";

const drawerWidth = "100%";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    border: "1px solid #233047",
    minWidth: 130,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const BridgeUpAppBar = (props) => {
  const { window } = props;

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [compAnchorEl, setCompAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const compOpen = Boolean(compAnchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCompClick = (event) => {
    setCompAnchorEl(event.currentTarget);
  };

  const handleCompClose = () => {
    setCompAnchorEl(null);
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const drawer = (
    <div
      style={{
        backgroundColor: "#030914",
        padding: "1rem 1rem",
        height: "100vh",
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Box
          sx={{
            alignItems: "center",
            flexGrow: 1,
            display: { xs: "flex", md: "none" },
          }}
        >
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://bridgeup.com/"
          >
            <Logo />
          </a>
        </Box>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleDrawerToggle}
          color="inherit"
        >
          <CloseIcon />
        </IconButton>
      </Stack>

      <List>
        <ListItem style={{ padding: "0" }}>
          <ListItemButton
            style={{ padding: "0" }}
            sx={{ px: 0, backgroundColor: "#030914" }}
          >
            <Accordion
              disableGutters
              sx={{ px: 0, backgroundColor: "#030914" }}
            >
              <AccordionSummary
                aria-controls="panel2a-content"
                id="panel2a-header"
                sx={{ px: 0, backgroundColor: "#030914" }}
              >
                <ListItemIcon className="menu-icon">
                  <InvestorsIcon />
                </ListItemIcon>
                <Link
                  href="https://bridgeup.com/investors"
                  underline="none"
                  sx={{
                    display: "block",
                    fontWeight: 700,
                    fontSize: "16px",
                    lineHeight: "22px",
                    px: 0,
                  }}
                  color={"white"}
                  px={2}
                >
                  Investors
                </Link>
              </AccordionSummary>
            </Accordion>
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem style={{ padding: "0" }}>
          <ListItemButton
            style={{ padding: "0" }}
            sx={{ px: 0, backgroundColor: "#030914" }}
          >
            <Accordion
              disableGutters
              sx={{ px: 0, backgroundColor: "#030914", width: "100%" }}
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                sx={{ px: 0, backgroundColor: "#030914" }}
              >
                <ListItemIcon className="menu-icon">
                  <ResourcesIcon />
                </ListItemIcon>
                <ListItemText
                  style={{ fontWeight: "700" }}
                  primary={"Resources"}
                  sx={{ fontSize: "16px", fontWeight: 700 }}
                />
                <AccordionDetails />
              </AccordionSummary>
              <AccordionDetails
                sx={{ px: 5, py: 0, backgroundColor: "#030914" }}
              >
                <Link
                  href="https://bridgeup.com/contact"
                  underline="none"
                  sx={{
                    display: "block",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "22px",
                    p: 2,
                  }}
                >
                  Contact us
                </Link>
                <Divider />
                <Link
                  href="https://bridgeup.com/faq"
                  underline="none"
                  sx={{
                    display: "block",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "22px",
                    p: 2,
                  }}
                >
                  FAQ's
                </Link>
              </AccordionDetails>
            </Accordion>
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem style={{ padding: "0" }}>
          <ListItemButton
            style={{ padding: "0" }}
            sx={{ px: 0, backgroundColor: "#030914" }}
          >
            <Accordion
              disableGutters
              sx={{ px: 0, backgroundColor: "#030914", width: "100%" }}
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                sx={{ px: 0, backgroundColor: "#030914" }}
              >
                <ListItemIcon className="menu-icon">
                  <CompanyIcon />
                </ListItemIcon>
                <ListItemText
                  primary={"Company"}
                  sx={{ fontSize: "16px", fontWeight: 700 }}
                />
                <AccordionDetails />
              </AccordionSummary>
              <AccordionDetails
                sx={{ px: 5, py: 0, backgroundColor: "#030914" }}
              >
                <Link
                  href="https://bridgeup.com/about"
                  underline="none"
                  sx={{
                    display: "block",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "22px",
                    p: 2,
                  }}
                >
                  About us
                </Link>
                <Divider />
                <Link
                  href="https://bridgeup.com/blog"
                  underline="none"
                  sx={{
                    display: "block",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "22px",
                    p: 2,
                  }}
                >
                  Blog
                </Link>
              </AccordionDetails>
            </Accordion>
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem style={{ padding: "0" }}>
          <ListItemButton
            style={{ padding: "0" }}
            sx={{ px: 0, backgroundColor: "#030914" }}
          >
            <Accordion
              disableGutters
              sx={{ px: 0, backgroundColor: "#030914" }}
            >
              <AccordionSummary
                aria-controls="panel2a-content"
                id="panel2a-header"
                sx={{ px: 0, backgroundColor: "#030914" }}
              >
                <ListItemIcon className="menu-icon">
                  <CalculatorIcon />
                </ListItemIcon>
                <Link
                  href="https://calculator.bridgeup.com/"
                  underline="none"
                  sx={{
                    display: "block",
                    fontWeight: 700,
                    fontSize: "16px",
                    lineHeight: "22px",
                    px: 0,
                  }}
                  color={"white"}
                  px={2}
                >
                  Calculator
                </Link>
              </AccordionSummary>
            </Accordion>
          </ListItemButton>
        </ListItem>
      </List>
      <Box mb={3} position="absolute" bottom="0px" width="90%">
        <Stack direction="row" justifyContent="space-between" spacing={1}>
          <Button
            variant="outlined"
            disableElevation
            disableFocusRipple
            onClick={login}
            disableRipple
            fullWidth
          >
            LOGIN
          </Button>
          <Button
            variant="outlined"
            style={{ whiteSpace: "nowrap" }}
            disableElevation
            disableFocusRipple
            onClick={signup}
            disableRipple
            fullWidth
          >
            GET CAPITAL
          </Button>
        </Stack>
      </Box>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        className="navbar-container"
        sx={{
          bgcolor: "#030914",
          padding: "1rem 1rem",
          backgroundImage: "unset",
        }}
      >
        <Container maxWidth="xl" className="navbar-wrapper">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", xl: "flex" } }}>
              <a
                target="_blank"
                href="https://bridgeup.com/"
                rel="noopener noreferrer"
                style={{ display: "flex" }}
              >
                <Logo />
              </a>
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", sm: "flex" },
                justifyContent: "center",
              }}
            >
              <Link
                href="https://bridgeup.com/investors"
                underline="none"
                sx={{
                  my: 2,
                  display: "block",
                  fontWeight: 500,
                  fontSize: "16px",
                  lineHeight: "22px",
                }}
                px={2}
              >
                Investors
              </Link>
              <Button
                variant="text"
                id="demo-customized-button"
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
                disableRipple
              >
                Resources
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                disableAutoFocusItem
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose} disableRipple>
                  <Link
                    href="https://bridgeup.com/contact"
                    underline="none"
                    sx={{
                      display: "block",
                      fontWeight: 500,
                      fontSize: "16px",
                      lineHeight: "22px",
                    }}
                  >
                    Contact us
                  </Link>
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleClose} disableRipple>
                  <Link
                    href="https://bridgeup.com/faq"
                    underline="none"
                    sx={{
                      display: "block",
                      fontWeight: 500,
                      fontSize: "16px",
                      lineHeight: "22px",
                    }}
                  >
                    FAQ's
                  </Link>
                </MenuItem>
              </StyledMenu>
              <Button
                variant="text"
                id="demo-customized-bt"
                aria-controls={compOpen ? "demo-comp-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={compOpen ? "true" : undefined}
                disableElevation
                onClick={handleCompClick}
                endIcon={<KeyboardArrowDownIcon />}
                disableRipple
              >
                Company
              </Button>
              <StyledMenu
                id="demo-comp-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-comp-button",
                }}
                disableAutoFocusItem
                anchorEl={compAnchorEl}
                open={compOpen}
                onClose={handleCompClose}
              >
                <MenuItem onClick={handleCompClose} disableRipple>
                  <Link
                    href="https://bridgeup.com/about"
                    underline="none"
                    sx={{
                      display: "block",
                      fontWeight: 500,
                      fontSize: "16px",
                      lineHeight: "22px",
                    }}
                  >
                    About us
                  </Link>
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleCompClose} disableRipple>
                  <Link
                    href="https://bridgeup.com/blog"
                    underline="none"
                    sx={{
                      display: "block",
                      fontWeight: 500,
                      fontSize: "16px",
                      lineHeight: "22px",
                    }}
                  >
                    Blog
                  </Link>
                </MenuItem>
              </StyledMenu>
              <Link
                href="https://calculator.bridgeup.com/"
                underline="none"
                sx={{
                  my: 2,
                  display: "block",
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "22px",
                  color: "#fff",
                }}
                px={2}
              >
                Calculator
              </Link>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", sm: "none", md: "flex" },
                justifyContent: "right",
              }}
            >
              <Button
                variant="anchor"
                disableElevation
                disableFocusRipple
                onClick={login}
                disableRipple
                sx={{ mx: 2 }}
              >
                LOGIN
              </Button>
              <Button
                variant="outlined"
                disableElevation
                style={{ whiteSpace: "nowrap" }}
                disableFocusRipple
                onClick={signup}
                disableRipple
              >
                GET CAPITAL
              </Button>
            </Box>
            <Box sx={{ flexGrow: 0, display: { xs: "flex", sm: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleDrawerToggle}
                color="inherit"
                sx={{ p: 0 }}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          anchor="right"
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  );
};
export default BridgeUpAppBar;
