import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ReactComponent as Logo } from "../assets/logo.svg";
import { login, signup } from "../common";
import * as React from "react";

function BridgeUpFooter() {
  const mobile = !useMediaQuery("(min-width:769px)");
  return (
    <Grid className="footer-wrapper">
      <Grid className="footer-top" container spacing={{ xs: 2, sm: 3 }} p={5}>
        <Grid item xs={12} sm={"auto"}>
          <Logo />
          <Typography
            sx={{
              fontSize: "14px",
              color: "#a3aed0",
              marginTop: "0",
            }}
            fontWeight={700}
            mt={mobile ? 1 : 1}
            mb={mobile ? 1 : 2}
          >
            Revenue is your best asset
          </Typography>
        </Grid>
        <Grid
          className="footer-links"
          container
          item
          direction={mobile ? "column" : "row"}
          xs={12}
          sm={true}
          columnSpacing={4}
          justifyContent="space-between"
        >
          <Grid className="footer-link-wrapper" item xs={6} md>
            <Typography variant="body1" color="text.primary" fontWeight={700}>
              PRODUCTS
            </Typography>

            <Link
              href="https://bridgeup.com/investors"
              underline="none"
              sx={{ cursor: "pointer" }}
            >
              <Typography
                sx={{ color: "#a3aed0", mt: 1 }}
                variant="body2"
                color="text.primary"
                fontWeight={700}
                whiteSpace="nowrap"
              >
                For Investors
              </Typography>
            </Link>
          </Grid>
          <Grid className="footer-link-wrapper" item xs={6} md>
            <Typography variant="body1" color="text.primary" fontWeight={700}>
              COMPANY
            </Typography>
            <Link
              href="https://bridgeup.com/about"
              underline="none"
              sx={{ cursor: "pointer" }}
            >
              <Typography
                sx={{ color: "#a3aed0", mt: 1 }}
                variant="body2"
                whiteSpace="nowrap"
                color="text.primary"
                fontWeight={700}
              >
                About
              </Typography>
            </Link>
            <Link
              href="https://angel.co/company/bridgeup-1"
              underline="none"
              sx={{ cursor: "pointer" }}
            >
              <Typography
                sx={{ color: "#a3aed0", mt: 1 }}
                variant="body2"
                color="text.primary"
                whiteSpace="nowrap"
                fontWeight={700}
              >
                Careers
              </Typography>
            </Link>
            <Link
              href="https://bridgeup.com/blog"
              underline="none"
              sx={{ cursor: "pointer" }}
            >
              <Typography
                sx={{ color: "#a3aed0", mt: 1 }}
                variant="body2"
                color="text.primary"
                whiteSpace="nowrap"
                fontWeight={700}
              >
                Blog
              </Typography>
            </Link>
          </Grid>
          <Grid className="footer-link-wrapper" item xs={6} md>
            <Typography variant="body1" color="text.primary" fontWeight={700}>
              RESOURCES
            </Typography>
            <Link
              href="https://bridgeup.com/faq"
              underline="none"
              sx={{ cursor: "pointer" }}
            >
              <Typography
                sx={{ color: "#a3aed0", mt: 1 }}
                variant="body2"
                color="text.primary"
                whiteSpace="nowrap"
                fontWeight={700}
              >
                FAQs
              </Typography>
            </Link>
            <Link
              href="https://bridgeup.com/contact"
              underline="none"
              sx={{ cursor: "pointer" }}
            >
              <Typography
                sx={{ color: "#a3aed0", mt: 1 }}
                variant="body2"
                whiteSpace="nowrap"
                color="text.primary"
                fontWeight={700}
              >
                Contact
              </Typography>
            </Link>
          </Grid>
          <Grid className="footer-link-wrapper" item xs={6} md>
            <Typography variant="body1" color="text.primary" fontWeight={700}>
              CALCULATOR
            </Typography>
            <Link
              href="https://calculator.bridgeup.com/"
              underline="none"
              sx={{ cursor: "pointer" }}
            >
              <Typography
                sx={{ color: "#a3aed0", mt: 1 }}
                variant="body2"
                whiteSpace="nowrap"
                color="text.primary"
                fontWeight={700}
              >
                BridgeUp Calculator
              </Typography>
            </Link>
          </Grid>
        </Grid>

        <Grid
          sm={"auto"}
          container
          spacing={0}
          alignItems="right"
          justify="right"
          item
          sx={{
            display: { xs: "none", md: "block" },
          }}
        >
          <Grid
            item
            className="bu-footer-butttons"
            direction="row"
            spacing={0}
            justifyContent="right"
          >
            <Button
              variant="anchor"
              disableElevation
              disableFocusRipple
              onClick={login}
              disableRipple
              sx={{ mx: 2 }}
            >
              LOGIN
            </Button>
            <Button
              variant="outlined"
              disableElevation
              disableFocusRipple
              onClick={signup}
              disableRipple
              style={{
                whiteSpace: "nowrap",
              }}
            >
              GET CAPITAL
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Divider
        className="footer-divider"
        sx={{ mb: 5, mt: mobile ? 0 : 5, mx: 10 }}
      />
      <Stack direction="column" alignItems="center" spacing={3}>
        {!mobile && (
          <Stack direction="row" spacing={5}>
            <Typography variant="body2" color="text.primary" fontWeight={400}>
              © BridgeUp Tech Private Limited
            </Typography>
            <Link
              href="https://bridgeup.com/privacy-policy"
              variant="body2"
              color="text.primary"
              fontWeight={400}
              style={{ textDecoration: "none" }}
            >
              Privacy Policy
            </Link>
            <Link
              href="https://bridgeup.com/terms "
              variant="body2"
              color="text.primary"
              fontWeight={400}
              style={{ textDecoration: "none" }}
            >
              Terms of Service
            </Link>
          </Stack>
        )}
        {mobile && (
          <Grid container direction="column" justify="center">
            <Grid
              item
              container
              spacing={0}
              alignItems="center"
              justifyContent="center"
              justify="center"
              align="center"
            >
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  color="text.primary"
                  fontWeight={400}
                >
                  © BridgeUp Tech Private Limited
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" justifyContent="center" spacing={2}>
                  <Link
                    href="https://bridgeup.com/privacy-policy"
                    variant="body2"
                    color="text.primary"
                    fontWeight={400}
                  >
                    Privacy Policy
                  </Link>
                  <Link
                    href="https://bridgeup.com/terms "
                    variant="body2"
                    color="text.primary"
                    fontWeight={400}
                  >
                    Terms of Service
                  </Link>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Typography
          variant="body2"
          sx={{
            color: "#A3AED0",
            padding: "0 10px 20px 10px",
            fontSize: "12px",
          }}
          fontWeight={400}
          align="center"
        >
          All product and company names are trademarks or registered trademarks
          of their respective holders. Use of them does not imply any
          affiliation with or endorsement by them.
        </Typography>
      </Stack>
    </Grid>
  );
}
export default BridgeUpFooter;
