import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";
import backgroundImage from "./assets/backgroundImage.png";
import BridgeUpAppBar from "./components/BridgeUpAppBar";
import StaticSection1 from "./components/StaticSection1";
import BridgeUpFooter from "./components/BridgeUpFooter";
import Calculator from "./components/Calculator";
import * as React from "react";
import "./App.css";
import BridgeUpHeader from "./components/BridgeUpHeader";
import { Grid } from "@mui/material";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BridgeUpAppBar />
      <Grid>
        <img
          className="five-line-img"
          src="https://assets.website-files.com/61f763783504a2866ee4eccb/6210b949dd97b019d43ac798_Frame%201923.svg"
        />
      </Grid>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <Toolbar />
        <BridgeUpHeader />
        <Container
          disableGutters
          maxWidth={false}
          sx={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundPosition: "left",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            width: "100%",
            height: "100%",
          }}
        >
          <Calculator />
          <StaticSection1 />
          <BridgeUpFooter />
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default App;
