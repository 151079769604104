import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const newtheme = createTheme({
  typography: {
    fontFamily: "Manrope",
    h3: {
      fontSize: "42px",
    },
    h4: {
      fontSize: "24px",
    },
    h6: {
      fontSize: "18px",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: "#030914",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            width: 1,
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#030914",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#2d477b",
            borderRadius: 2,
          },
        },
      },
    },
    MuiDivider: {
      variants: [
        {
          props: {
            variant: "dashed",
          },
          style: {
            borderStyle: "dashed",
            borderWidth: 1,
            borderColor: "#454E64",
          },
        },
      ],
    },
    MuiInputLabel: {
      defaultProps: {
        sx: {
          fontSize: "14px",
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        sx: {
          fontSize: "14px",
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: "0",
          background: "#030914",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          background: "#1B2434",
          minHeight: "4rem",
          fontWeight: 400,
          fontSize: "14px !important",
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "primary" },
          style: {
            minHeight: "3rem",
            color: "white",
            borderRadius: "1rem",
            lineHeight: "2rem",
            background: "linear-gradient(90deg, #62BDFF 0%, #3864FF 98.91%)",
            fontWeight: 700,
            fontSize: "1.2rem",
            letterSpacing: 1,
          },
        },
        {
          props: { variant: "contained" },
          style: {
            fontWeight: 700,
            background: "linear-gradient(90deg, #62BDFF 0%, #3864FF 98.91%)",
            letterSpacing: 1,
            padding: "12px 24px",
            fontSize: "18px",
            borderRadius: "61px",
            color: "white",
          },
        },
        {
          props: { variant: "text" },
          style: {
            fontWeight: 500,
            textTransform: "none",
            padding: "4px 10px",
            fontSize: "16px",
            color: "#A3AED0",
            ":hover": {
              background: "unset",
              color: "#62BDFF",
            },
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            padding: "12px 24px",
            fontWeight: 700,
            fontSize: "16px",
            borderWidth: 1,
            borderRadius: "61px",
            borderStyle: "solid",
            borderColor: "#62BDFF",
            color: "#fff",
            letterSpacing: 1,
            ":hover": {
              borderColor: "#62BDFF",
              color: "#62BDFF",
              background: "unset",
            },
          },
        },
        {
          props: { variant: "anchor" },
          style: {
            fontWeight: 700,
            fontSize: "16px",
            color: "#FFFFFF",
            textTransform: "none",
            padding: 0,
            ":hover": {
              background: "unset",
              color: "#62BDFF",
              borderBottom: "2px solid #62BDFF",
              borderRadius: 0,
            },
          },
        },
      ],
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#A3AED0",
          "&:hover": {
            color: "#62BDFF",
          },
        },
      },
    },
  },
  palette: {
    mode: "dark",
    background: {
      paper: "#030914",
    },
    primary: {
      main: "#62bdff",
    },
    secondary: {
      main: "#4470ff",
    },
    error: {
      main: "#fa002d",
    },
    success: {
      main: "#38ef7d",
    },
    warning: {
      main: "#fec570",
    },
  },
});
export const theme = responsiveFontSizes(newtheme);
