import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Chart } from "react-chartjs-2";
import {
  Chart as Chartjs,
  ArcElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
Chartjs.register(ArcElement, CategoryScale, LinearScale);

function createGradient(ctx, area, color) {
  const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);
  gradient.addColorStop(0, color.start);
  gradient.addColorStop(1, color.end);
  return gradient;
}

export function PieChart(props) {
  const { data } = props;

  const chartRef = useRef(null);

  const [chartData, setChartData] = useState({
    datasets: [],
  });

  useEffect(() => {
    const chart = chartRef.current;

    if (!chart) {
      return;
    }

    const doughnutColor = [
      {
        start: "rgba(7, 187, 144, 1)",
        end: "rgba(56, 239, 125, 0.86)",
      },
      {
        start: "rgba(75, 158, 255, 1)",
        end: "rgba(50, 98, 236, 1)",
      },
      {
        start: "rgba(19, 64, 182, 1)",
        end: "rgba(36, 85, 212, 1)",
      },
    ];

    const chartData = {
      labels: ["", "Diluted"],
      datasets: [
        {
          data: !data ? [99.9, 0.1] : data,
          backgroundColor: !data
            ? [
                createGradient(chart.ctx, chart.chartArea, doughnutColor[0]),
                createGradient(chart.ctx, chart.chartArea, doughnutColor[1]),
              ]
            : ["rgba(54, 83, 107, 1)", "rgba(255, 162, 75, 1)"],
          borderRadius: {
            outerStart: 0,
            outerEnd: 0,
            innerStart: 0,
            innerEnd: 0,
          },
          borderColor: !data
            ? [createGradient(chart.ctx, chart.chartArea, doughnutColor[2])]
            : ["transparent"],
          borderWidth: !data ? 2 : 10,
          hoverOffset: 5,
        },
      ],
    };
    setChartData(chartData);
  }, [data]);

  const options = {
    layout: {
      padding: 0,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        backgroundColor: "#233047",
        // callbacks: {
        //   label: function (tooltipItem, data) {
        //     console.log("tooltipItem.dataset.parsed", tooltipItem.dataset);
        //     return tooltipItem.dataset.data[0] + "%";
        //   },
        // },
      },
    },
    rotation: 29 * Math.PI - (25 / 180) * Math.PI,
  };

  return (
    <Box sx={{ width: 164, position: "relative" }}>
      <Chart ref={chartRef} type="pie" data={chartData} options={options} />
    </Box>
  );
}
