export const ARRTable = [
  {
    type: "SaaS",
    no_of_customers: "5-20",
    runway: "6-12 Months",
    ARR_percent: 20,
    InterestRate: 17,
  },
  {
    type: "SaaS",
    no_of_customers: "5-20",
    runway: "13-24 Months",
    ARR_percent: 25,
    InterestRate: 16,
  },
  {
    type: "SaaS",
    no_of_customers: "5-20",
    runway: "24+ Months",
    ARR_percent: 35,
    InterestRate: 15,
  },
  {
    type: "SaaS",
    no_of_customers: "21-50",
    runway: "6-12 Months",
    ARR_percent: 20,
    InterestRate: 16.5,
  },
  {
    type: "SaaS",
    no_of_customers: "21-50",
    runway: "13-24 Months",
    ARR_percent: 30,
    InterestRate: 15.5,
  },
  {
    type: "SaaS",
    no_of_customers: "21-50",
    runway: "24+ Months",
    ARR_percent: 40,
    InterestRate: 14,
  },
  {
    type: "SaaS",
    no_of_customers: "50+",
    runway: "6-12 Months",
    ARR_percent: 20,
    InterestRate: 18,
  },
  {
    type: "SaaS",
    no_of_customers: "50+",
    runway: "13-24 Months",
    ARR_percent: 25,
    InterestRate: 16.5,
  },
  {
    type: "SaaS",
    no_of_customers: "50+",
    runway: "24+ Months",
    ARR_percent: 30,
    InterestRate: 15.5,
  },
  {
    type: "Subscriptions",
    no_of_customers: "5-100",
    runway: "6-12 Months",
    ARR_percent: 20,
    InterestRate: 18,
  },
  {
    type: "Subscriptions",
    no_of_customers: "5-100",
    runway: "13-24 Months",
    ARR_percent: 25,
    InterestRate: 16.5,
  },
  {
    type: "Subscriptions",
    no_of_customers: "5-100",
    runway: "24+ Months",
    ARR_percent: 30,
    InterestRate: 15.5,
  },
  {
    type: "Services",
    no_of_customers: "5-100",
    runway: "6-12 Months",
    ARR_percent: 20,
    InterestRate: 16.5,
  },
  {
    type: "Services",
    no_of_customers: "5-100",
    runway: "13-24 Months",
    ARR_percent: 30,
    InterestRate: 15.5,
  },
  {
    type: "Services",
    no_of_customers: "5-100",
    runway: "24+ Months",
    ARR_percent: 40,
    InterestRate: 14.5,
  },
  {
    type: "Others",
    no_of_customers: "5-100",
    runway: "6-12 Months",
    ARR_percent: 20,
    InterestRate: 16.5,
  },
  {
    type: "Others",
    no_of_customers: "5-100",
    runway: "13-24 Months",
    ARR_percent: 30,
    InterestRate: 15.5,
  },
  {
    type: "Others",
    no_of_customers: "5-100",
    runway: "24+ Months",
    ARR_percent: 40,
    InterestRate: 14.5,
  },
];

export const BidOffer = {
  12: 0.9379,
  12.25: 0.9367,
  12.5: 0.9355,
  12.75: 0.9342,
  13: 0.933,
  13.25: 0.9318,
  13.5: 0.9306,
  13.75: 0.9293,
  14: 0.9281,
  14.25: 0.9269,
  14.5: 0.9257,
  14.75: 0.9245,
  15: 0.9233,
  15.25: 0.9221,
  15.5: 0.9209,
  15.75: 0.9197,
  16: 0.9185,
  16.25: 0.9173,
  16.5: 0.9161,
  16.75: 0.9149,
  17: 0.9137,
  17.25: 0.9125,
  17.5: 0.9113,
  17.75: 0.9101,
  18: 0.909,
  18.25: 0.9078,
  18.5: 0.9066,
  18.75: 0.9054,
  19: 0.9043,
  19.25: 0.9031,
  19.5: 0.9019,
  19.75: 0.9008,
  20: 0.8996,
};
export const valuation = {
  SaaS: 10,
  Services: 7,
  Subscriptions: 9,
  Others: 7,
};
export const Type = ["SaaS", "Subscriptions", "Services", "Others"];
export const NoOfCust = ["5-20", "21-50", "50+"];
export const Runway = [
  {
    label: "6-12 months",
    value: "6-12 Months",
  },
  {
    label: "13-24 months",
    value: "13-24 Months",
  },
  {
    label: "24+ months",
    value: "24+ Months",
  },
];
